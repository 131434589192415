var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "grid grid-cols-2 gap-4 text-muted"
  }, [_c('dt', {
    staticClass: "text-muted"
  }, [_vm.icon ? _c('i', {
    staticClass: "fa fa-fw mr-1",
    class: _vm.icon
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.label))]), _c('dd', {
    staticClass: "text-muted font-w500 mb-0 pl-1",
    class: {
      'text-uppercase': _vm.uppercase,
      'text-capitalize': _vm.capitalize
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.blank ? '' : _vm.value))];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }